import type IEndPoint from 'interfaces/EndPointTypes.ts';
import { AuthProtect, BaseURLSelect } from '@/common/enum';
import { EQFormData } from 'pages/Equipment/hook/useEQForm.ts';

export const getEquipmentDataSet = (url: string): IEndPoint => ({
  authorization: AuthProtect.REQUIRED,
  method: 'GET',
  url,
  defaultBaseURL: BaseURLSelect.DMA,
});

export const getEquipmentData = (url: string): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: 'GET',
    url,
    defaultBaseURL: BaseURLSelect.DMA,
  };
};

export const postEquipmentData = (url: string, isNew: boolean, data: EQFormData): IEndPoint => {
  return {
    authorization: AuthProtect.REQUIRED,
    method: isNew ? 'POST' : 'PUT',
    url,
    defaultBaseURL: BaseURLSelect.DMA,
    data,
  };
};
